import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Col } from 'reactstrap'
import Img from 'gatsby-image'
import Container from '../container'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Wrapper from '../wrapper/wrapper'
import SideBar from '../Side-bar/side-bar.board.js'
import Header from '../Header/noLangHeader'
import Breadcrumb from '../Breadcrumb/breadcrumb'
import Footer from '../Footer/footer'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import footerStyle from '../Footer/footer.module.css'
import Navbar from '../Navbar/navbar-board'
import { GlobalStyle } from '../../utils/global'
import Col4BoardTable from '../BoardsTable/4Column_boardTable'
import Col7BoardTable from '../BoardsTable/7Column_boardTable'
import Col8BoardTable from '../BoardsTable/8Col_boardTable'
import Column8BoardTable from '../BoardsTable/8Column_boardTable'
import Col5BoardTable from '../BoardsTable/5Col_boardTable'
import Col3BoardTable from '../BoardsTable/3Col_boardTable'
import Menu from '../Board-Menu/menu'
import '../Mobile-menu/mobile.menu.css'
import ChartY from '../Chart/chart.board'
import ChartX from '../Chart/chart.shared.employtrend'
import ChartXB from '../Chart/chart.shared.employtrendB'
import ChartXC from '../Chart/chart.shared.employtrendC'
import ChartXD from '../Chart/chart.shared.employtrendD'
import ChartC from '../Chart/chart.shared.consumerconfidence'
import ChartD from '../Chart/chart.interestrate'
import ChartM from '../Chart/charts.sharedMigration'
import ChartMB from '../Chart/charts.sharedMigrationB'
import ChartMC from '../Chart/charts.sharedMigrationC'
import BoardChartA from '../Chart/boardA.chart'
import BoardChartB from '../Chart/boardB.chart'
import BoardChartC from '../Chart/boardC.chart'
import MobileTab from '../Mobile-Tab/mobile-tab'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import '../Content/content.css'

const contentStyle = {
  background: 'rgba(255,255,255,0)',
  width: '80%',
  border: 'none',
  background: 'rgba(219,47,37,0.8)',
}

const addStyle = {
  padding: '5px;',
}

class Template extends React.Component {
  static defaultProps = {
    name: 'stranger',
  }
  constructor(props) {
    super(props)
    this.children = this.props.children
  }
  getYear() {
    return new Date().getFullYear()
  }
  topFunction() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handeleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handeleScroll)
  }
  handeleScroll() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('myBtn').style.display = 'block'
    } else {
      document.getElementById('myBtn').style.display = 'none'
    }
  }
  render() {
    const natl = get(this, 'props.data.ca.edges[0].node')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    let post = this.props.post

    const location = this.props.location
    const url = location.pathname
    const { sharedEmpChart = {} } = this.props
    const { sharedEmpChartB = {} } = this.props
    const { sharedEmpChartC = {} } = this.props
    const { sharedEmpChartD = {} } = this.props
    const { sharedConChart = {} } = this.props
    const { sharedIRChart = {} } = this.props
    const { sharedMGRChart = {} } = this.props
    const { sharedMGRChartB = {} } = this.props
    const { sharedMGRChartC = {} } = this.props
    const { boardChart = {} } = this.props
    const { boardChartB = {} } = this.props
    const { boardChartC = {} } = this.props
    const { showIframe } = this.props
    const { csvIframe } = this.props
    const possibleSlugs = [
      'Employment Trends',
      'Interest Rate Changes',
      'Consumer Confidence',
      'Migration',
      'About',
    ]
    const actualSlugs = post
      ? post.boardSubPages
        ? post.boardSubPages.map(a => a.boardPageType)
        : []
      : []
    let intersection = possibleSlugs
      .filter(x => !actualSlugs.includes(x))
      .concat(actualSlugs.filter(x => !possibleSlugs.includes(x)))

    return (
      <Container>
        <GlobalStyle />
        <Header pathname={url} />
        <Navbar />

        <Wrapper>
          {/* <Breadcrumb title={this.props.board ? post.renderedTitle : ''} /> */}
          <div style={{ paddingTop: '55px' }}></div>

          {this.props.location.pathname == '/en-CA/' ? (
            <Modal
              isOpen={this.props.chartAProp}
              toggle={this.props.closeChartA}
            >
              <ModalHeader toggle={this.props.closeChartA}>Chart A</ModalHeader>
              <ModalBody>
                <img src={natl.chartA.fluid.src} />
              </ModalBody>
            </Modal>
          ) : null}
          {this.props.location.pathname == '/en-CA/' ? (
            <Modal
              isOpen={this.props.chartBProp}
              toggle={this.props.closeChartB}
            >
              <ModalHeader toggle={this.props.closeChartB}>Chart B</ModalHeader>
              <ModalBody>
                <img src={natl.chartB.fluid.src} />
              </ModalBody>
            </Modal>
          ) : null}
          <Button
            onClick={this.topFunction}
            id="myBtn"
            title="Go to top"
            className="scroll-btn"
          >
            <img
              src={require('../../images/btn_backtop.png')}
              alt="Scroll to top"
              title="Scroll to top"
            />
          </Button>
          {this.children}

          {this.props.board ? (
            <div className="content-wrapper">
              <div className="row split">
                <div className="col-md">
                  <div className="entry-header">
                    <Helmet title={`${post.title} | ${siteTitle}`} />
                    {/* <!--  ==========================================================  -->
        <!--  START ---- PAGE TITLE, PAGE HEADLINE, PAGE CONTENT ==========================  -->
        <!--  ==========================================================  --> */}
                    <h1
                      className="entry-title"
                      dangerouslySetInnerHTML={{
                        __html: post.renderedTitle,
                      }}
                    />
                  </div>

                  <div className="entry-content">
                    <div className="entry-sub">
                      {post.headline !== null && (
                        <h2
                          className="entry-headline"
                          dangerouslySetInnerHTML={{
                            __html: post.headline,
                          }}
                        />
                      )}
                      {post.sharedContent !== null && (
                        <h2
                          className="entry-headline"
                          dangerouslySetInnerHTML={{
                            __html: post.sharedContent.sharedHeadline,
                          }}
                        />
                      )}

                      {post.employmentSharedC !== null && (
                        <h2
                          className="entry-headline"
                          dangerouslySetInnerHTML={{
                            __html: post.employmentSharedC.sharedHeadline,
                          }}
                        />
                      )}

                      {post.migrationSharedC !== null && (
                        <h2
                          className="entry-headline"
                          dangerouslySetInnerHTML={{
                            __html: post.migrationSharedC.sharedHeadline,
                          }}
                        />
                      )}
                      {post.interestRateSharedContent !== null && (
                        <h2
                          className="entry-headline"
                          dangerouslySetInnerHTML={{
                            __html:
                              post.interestRateSharedContent.sharedHeadline,
                          }}
                        />
                      )}
                      {post.boardAboutSharedC !== null && (
                        <h2
                          className="entry-headline"
                          dangerouslySetInnerHTML={{
                            __html: post.boardAboutSharedC.sharedHeadline,
                          }}
                        />
                      )}
                      <div>
                        <div className="text-chart-div">
                          <div className="mainFloatContainer">
                            <div className="LeftFloat hideOnMobile">
                              {boardChart.length ? (
                                <BoardChartA
                                  boardChart={boardChart}
                                  name={this.props.name1}
                                />
                              ) : null}
                              {boardChartB && boardChartB.length ? (
                                <BoardChartB
                                  boardChartB={boardChartB}
                                  name={this.props.name1}
                                />
                              ) : null}
                              {boardChartC && boardChartC.length ? (
                                <BoardChartC
                                  boardChartC={boardChartC}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {sharedEmpChart && sharedEmpChart.length ? (
                                <ChartX
                                  sharedEmpChart={sharedEmpChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {sharedConChart && sharedConChart.length ? (
                                <ChartC
                                  sharedConChart={sharedConChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {sharedIRChart && sharedIRChart.length ? (
                                <ChartD
                                  sharedIRChart={sharedIRChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {sharedMGRChart && sharedMGRChart.length ? (
                                <ChartM
                                  sharedMGRChart={sharedMGRChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {this.props.chartData.length > 0 ? (
                                <ChartY
                                  chartData={this.props.chartData}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {this.props.chartData2.length > 0 ? (
                                <ChartY
                                  chartData={this.props.chartData2}
                                  name={this.props.name2}
                                />
                              ) : null}

                              {this.props.chartData3.length > 0 ? (
                                <ChartY
                                  chartData={this.props.chartData3}
                                  name={this.props.name3}
                                />
                              ) : null}
                            </div>

                            {/* <!--  ==========================================================  -->
        <!--  START ---- HIDE ON MOBILE ==========================  -->
        <!--  ==========================================================  --> */}
                            <div className="LeftFloat showOnMobile">
                              {sharedEmpChart && sharedEmpChart.length ? (
                                <ChartX
                                  sharedEmpChart={sharedEmpChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {sharedConChart && sharedConChart.length ? (
                                <ChartC
                                  sharedConChart={sharedConChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {sharedIRChart && sharedIRChart.length ? (
                                <ChartD
                                  sharedIRChart={sharedIRChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {sharedMGRChart && sharedMGRChart.length ? (
                                <ChartM
                                  sharedMGRChart={sharedMGRChart}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {this.props.chartData.length > 0 ? (
                                <ChartY
                                  chartData={this.props.chartData}
                                  name={this.props.name1}
                                />
                              ) : null}

                              {this.props.chartData2.length > 0 ? (
                                <ChartY
                                  chartData={this.props.chartData2}
                                  name={this.props.name2}
                                />
                              ) : null}

                              {this.props.chartData3.length > 0 ? (
                                <ChartY
                                  chartData={this.props.chartData3}
                                  name={this.props.name3}
                                />
                              ) : null}
                            </div>

                            {/* <!--  ==========================================================  -->
        <!--  END ---- HIDE ON MOBILE ==========================  -->
        <!--  ==========================================================  --> */}
                            <div className="RightFloat">
                              {post.mainContent !== null && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.mainContent.childContentfulRichText
                                        .html,
                                  }}
                                />
                              )}
                              {post.boardAboutSharedC !== null && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.boardAboutSharedC.sharedContent
                                        .childMarkdownRemark.html,
                                  }}
                                />
                              )}
                              {post.sharedContent !== null && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.sharedContent.sharedContent
                                    // .childContentfulRichText.html,
                                  }}
                                />
                              )}
                              {post.employmentSharedC !== null && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.employmentSharedC.sharedContent
                                        .childContentfulRichText.html,
                                  }}
                                />
                              )}

                              {post.migrationSharedC !== null && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.migrationSharedC.sharedContent
                                        .childContentfulRichText.html,
                                  }}
                                />
                              )}
                              {post.interestRateSharedContent !== null && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.interestRateSharedContent
                                        .sharedContent.childContentfulRichText
                                        .html,
                                  }}
                                />
                              )}
                            </div>
                          </div>

                          {/* <!--  ==========================================================  -->
        <!--  START -- SECTION B==========================  -->
        <!--  ==========================================================  --> */}

                          <div className="mainFloatContainer">
                            {post.employmentSharedC !== null &&
                              post.employmentSharedC.sharedHeadlineB && (
                                <div>
                                  <hr />
                                  <h2
                                    className="entry-headline"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.employmentSharedC.sharedHeadlineB,
                                    }}
                                  />
                                </div>
                              )}
                            <div className="LeftFloat hideOnMobile">
                              {sharedEmpChartB && sharedEmpChartB.length ? (
                                <ChartXB sharedEmpChartB={sharedEmpChartB} />
                              ) : null}
                            </div>

                            <div className="LeftFloat hideOnMobile">
                              {sharedMGRChartB && sharedMGRChartB.length ? (
                                <ChartMB sharedMGRChartB={sharedMGRChartB} />
                              ) : null}
                            </div>

                            {/* <!--  ==========================================================  -->
<!--  START ---- HIDE ON MOBILE ==========================  -->
<!--  ==========================================================  --> */}
                            <div className="LeftFloat showOnMobile">
                              {/* {sharedEmpChartB && sharedEmpChartB.length ? (
                                <ChartXB
                                  sharedEmpChart={sharedEmpChartB}
                                  name={this.props.name1}
                                />
                              ) : null} */}
                            </div>

                            {/* <!--  ==========================================================  -->
<!--  END ---- HIDE ON MOBILE ==========================  -->
<!--  ==========================================================  --> */}
                            <div className="RightFloat">
                              {post.employmentSharedC !== null &&
                                post.employmentSharedC.sharedContentB && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.employmentSharedC.sharedContentB
                                          .childContentfulRichText.html,
                                    }}
                                  />
                                )}

                              {post.migrationSharedC !== null &&
                                post.migrationSharedC.sharedContentB && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.migrationSharedC.sharedContentB
                                          .childContentfulRichText.html,
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                          {/* <!--  ==========================================================  -->
        <!--  END -- SECTION B==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!--  START -- SECTION C==========================  -->
        <!--  ==========================================================  --> */}

                          <div className="mainFloatContainer">
                            {post.employmentSharedC !== null &&
                              post.employmentSharedC.sharedHeadlineC && (
                                <h2
                                  className="entry-headline"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.employmentSharedC.sharedHeadlineC,
                                  }}
                                />
                              )}
                            <div className="LeftFloat hideOnMobile">
                              {sharedEmpChartC && sharedEmpChartC.length ? (
                                <ChartXC sharedEmpChartC={sharedEmpChartC} />
                              ) : null}
                            </div>

                            <div className="LeftFloat hideOnMobile">
                              {sharedMGRChartC && sharedMGRChartC.length ? (
                                <ChartMC sharedMGRChartC={sharedMGRChartC} />
                              ) : null}
                            </div>



                            {/* <!--  ==========================================================  -->
<!--  START ---- HIDE ON MOBILE ==========================  -->
<!--  ==========================================================  --> */}
                            <div className="LeftFloat showOnMobile">
                              {sharedEmpChartC && sharedEmpChartC.length ? (
                                <ChartXC
                                  sharedEmpChartC={sharedEmpChartC}
                                  name={this.props.name1}
                                />
                              ) : null}
                            </div>

                            {/* <!--  ==========================================================  -->
<!--  END ---- HIDE ON MOBILE ==========================  -->
<!--  ==========================================================  --> */}
                            <div className="RightFloat">
                              {post.employmentSharedC !== null &&
                                post.employmentSharedC.sharedContentC && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.employmentSharedC.sharedContentC
                                          .childContentfulRichText.html,
                                    }}
                                  />
                                )}

                              {post.migrationSharedC !== null &&
                                post.migrationSharedC.sharedContentC && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.migrationSharedC.sharedContentC
                                          .childContentfulRichText.html,
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                          {/* <!--  ==========================================================  -->
        <!--  END -- SECTION C==========================  -->
        <!--  ==========================================================  --> */}
                          {/* <!--  ==========================================================  -->
        <!--  START -- SECTION D==========================  -->
        <!--  ==========================================================  --> */}

                          <div className="mainFloatContainer">
                            {post.employmentSharedC !== null &&
                              post.employmentSharedC.sharedHeadlineD && (
                                <h2
                                  className="entry-headline"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      post.employmentSharedC.sharedHeadlineD,
                                  }}
                                />
                              )}
                            <div className="LeftFloat hideOnMobile">
                              {sharedEmpChartD && sharedEmpChartD.length ? (
                                <ChartXD sharedEmpChartD={sharedEmpChartD} />
                              ) : null}
                            </div>





                            {/* <!--  ==========================================================  -->
<!--  START ---- HIDE ON MOBILE ==========================  -->
<!--  ==========================================================  --> */}
                            <div className="LeftFloat showOnMobile">
                              {/*sharedEmpChartD && sharedEmpChartD.length ? (
                                <ChartXD
                                  sharedEmpChartD={sharedEmpChartD}
                                  name={this.props.name1}
                                />
                              ) : null*/}
                            </div>

                            {/* <!--  ==========================================================  -->
<!--  END ---- HIDE ON MOBILE ==========================  -->
<!--  ==========================================================  --> */}
                            <div className="RightFloat">
                              {post.employmentSharedC !== null &&
                                post.employmentSharedC.sharedContentD && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        post.employmentSharedC.sharedContentD
                                          .childContentfulRichText.html,
                                    }}
                                  />
                                )}


                            </div>
                          </div>
                          {/* <!--  ==========================================================  -->
        <!--  END -- SECTION D==========================  -->
        <!--  ==========================================================  --> */}
                        </div>


                        {/* <!--  ==========================================================  -->
        <!--  START ---- PAGE TITLE, PAGE HEADLINE, PAGE CONTENT ==========================  -->
        <!--  ==========================================================  --> */}

                        {/* <!--  ==========================================================  -->
        <!--  START--- BOARDS HOMEPAGE SHEETS ==========================  -->
        <!--  ==========================================================  --> */}

                        <div className="boardSheets">
                          {/*   <iframe
                          csvIframe={this.props.csvIframe}                    
                            className="table-iframe"
                          ></iframe> */}
                          {this.props.fourColTableData.length > 0 ? (
                            <Col4BoardTable
                              tableData={this.props.fourColTableData}
                              name={this.props.fourColTableName}
                              footName={this.props.fourColFooterName}
                            />
                          ) : null}

                          {this.props.fourColTableData2.length > 0 ? (
                            <Col4BoardTable
                              tableData={this.props.fourColTableData2}
                              name={this.props.fourColTableName2}
                              footName={this.props.fourColFooterName}
                            />
                          ) : null}
                          {this.props.fourColTableData3.length > 0 ? (
                            <Col4BoardTable
                              tableData={this.props.fourColTableData3}
                              name={this.props.fourColTableName3}
                            />
                          ) : null}
                          {this.props.fourColTableData4.length > 0 ? (
                            <Col4BoardTable
                              tableData={this.props.fourColTableData4}
                              name={this.props.fourColTableName4}
                            />
                          ) : null}
                          {this.props.threeColTableData.length > 0 ? (
                            <Col3BoardTable
                              tableData={this.props.threeColTableData}
                              name={this.props.threeColTableName}
                            />
                          ) : null}
                          {this.props.fiveColTableData.length > 0 ? (
                            <Col5BoardTable
                              tableData={this.props.fiveColTableData}
                              name={this.props.fiveColTableName}
                            />
                          ) : null}
                          {this.props.fiveColTableData2.length > 0 ? (
                            <Col5BoardTable
                              tableData={this.props.fiveColTableData2}
                              name={this.props.fiveColTableName2}
                            />
                          ) : null}

                          {this.props.eightColTableData.length > 0 ? (
                            <Column8BoardTable
                              tableData={this.props.eightColTableData}
                              name={this.props.eightColTableName}
                            />
                          ) : null}
                          {this.props.eightColTableData2.length > 0 ? (
                            <Column8BoardTable
                              tableData={this.props.eightColTableData2}
                              name={this.props.eightColTableName2}
                            />
                          ) : null}
                          {this.props.eightColTableData3.length > 0 ? (
                            <Column8BoardTable
                              tableData={this.props.eightColTableData3}
                              name={this.props.eightColTableName3}
                            />
                          ) : null}

                          {this.props.sevenColTableData.length > 0 ? (
                            <Col7BoardTable
                              tableData={this.props.sevenColTableData}
                              name={this.props.sevenColTableName}
                              footName={this.props.fourColFooterName}
                            />
                          ) : null}

                          {this.props.sevenColTableData2.length > 0 ? (
                            <Col7BoardTable
                              tableData={this.props.sevenColTableData2}
                              name={this.props.sevenColTableName2}
                              footName={this.props.fourColFooterName}
                            />
                          ) : null}

                          {this.props.tableData.length > 0 ? (
                            <Col8BoardTable
                              tableData={this.props.tableData2}
                              name={this.props.name1}
                            />
                          ) : null}

                          {/*    {this.props.chatData.length > 0 ? (
                            <TCol8BoardTable
                              chatData={this.props.chatData}
                              name={this.props.name1}
                            />
                          ) : null}

                          {this.props.chatData2.length > 0 ? (
                            <TCol8BoardTable
                              chatData={this.props.chatData2}
                              name={this.props.name2}
                            />
                          ) : null} */}

                          {/* <!--  ==========================================================  -->
        <!--  START--- ADDITIONAL CONTENT ==========================  -->
        <!--  ==========================================================  --> */}

                          {post.additionalContent !== null && (
                            <div
                              style={addStyle}
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.additionalContent.childMarkdownRemark
                                    .html,
                              }}
                            />
                          )}
                          {/* <!--  ==========================================================  -->
        <!--  END--- ADDITIONAL CONTENT  ==========================  -->
        <!--  ==========================================================  --> */}
                        </div>

                        {/* <!--  ==========================================================  -->
        <!--  START--- IFRAME ONLY FOR TILL  ==========================  -->
        <!--  ==========================================================  --> */}
                        {showIframe ? (
                          <iframe
                            src="https://content.crea.ca/hpi/tills.html"
                            width="924"
                            height="800"
                          ></iframe>
                        ) : null}
                        {/* <!--  ==========================================================  -->
        <!--  END--- IFRAME ONLY FOR TILL  ==========================  -->
        <!--  ==========================================================  --> */}

                        {/* <!--  ==========================================================  -->
        <!--  END--- BOARDS HOMEPAGE SHEETS ==========================  -->
        <!--  ==========================================================  --> */}
                        {/* <!--  ==========================================================  -->
        <!--  START--- BOARD INFORMATION ==========================  -->
        <!--  ==========================================================  --> */}

                        <div className="boardInfo">
                          {post.boardInfo !== null && (
                            <div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    post.boardInfo.childMarkdownRemark.html,
                                }}
                              />
                            </div>
                          )}

                          <Container>
                            <Row>
                              <Col>
                                {post.boardPresidentPhoto !== null && (
                                  <Img
                                    className="boardPresPhoto"
                                    fixed={post.boardPresidentPhoto.fixed}
                                    tracedSVG
                                  />
                                )}

                                {post.boardPresidentName !== null && (
                                  <div>
                                    {/* {post.boardRep} */}
                                    {post.boardRep || 'President'}
                                    <br />
                                    {post.boardPresidentName}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        {/* <!--  ==========================================================  -->
        <!--  END--- BOARDS HOMEPAGE SHEETS ==========================  -->
        <!--  ==========================================================  --> */}
                        {/* <!--  ==========================================================  -->
        <!-- START---  BOARD AND CREA DECLARATION HERE ==========================  -->
        <!--  ==========================================================  --> */}
                        <div className={footerStyle.boardCredit}>
                          {/* <!--  ==========================================================  -->
        <!-- START---  MOBILE SIDEBAR IN FOOTER ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!-- END---  MOBILE SIDEBAR IN FOOTER ==========================  -->
        <!--  ==========================================================  --> */}

                          <hr />
                          <p>
                            The information contained in this report has been
                            prepared by The Canadian Real Estate Association, in
                            co-operation with the {post.title}. The information
                            has been drawn from sources deemed to be reliable,
                            but the accuracy and completeness of the information
                            is not guaranteed. In providing this information,
                            neither The Canadian Real Estate Association nor the{' '}
                            {post.title} assumes any responsibility or
                            liability. Copyright&nbsp;&copy;{this.getYear()}.
                            The Canadian Real Estate Association. All rights
                            reserved. Reproduction in whole or in part is
                            prohibited without written permission.
                          </p>
                        </div>

                        {/* <!--  ==========================================================  -->
        <!-- END--- EDITING PAGE CONTENT HERE =============================  -->
        <!--  ==========================================================  --></div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--  ==========================================================  -->
        <!--  START--- DYNAMIC SIDEBAR ==========================  -->
        <!--  ==========================================================  --> */}
                <div className="col-sm- side-content mobile-style">
                  <hr />
                  <ThemeToggler>
                    {({ theme, toggleTheme, isOn, handleToggle }) => (
                      <>
                        <input
                          checked={theme === 'dark'}
                          onChange={e =>
                            toggleTheme(e.target.checked ? 'dark' : 'light')
                          }
                          className="react-switch-checkbox"
                          id={`react-switch-new`}
                          type="checkbox"
                        />
                        Dark Mode
                        <label
                          style={{ background: isOn && onColor }}
                          className="react-switch-label"
                          htmlFor={`react-switch-new`}
                        >
                          <span className={`react-switch-button`}>
                            <div className="sun_icon" />
                          </span>
                        </label>
                      </>
                    )}
                  </ThemeToggler>
                  <hr />
                  <div className="boardInformation sidebar-wrapper ">
                    <h5 className="text_upper">National Statistics</h5>
                    <ul>
                      <li>
                        <Link
                          activeStyle={{
                            color: '#345fa8',
                            fontWeight: 'bold',
                          }}
                          to={'/en-CA'}
                        >
                          National English
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeStyle={{
                            color: '#345fa8',
                            fontWeight: 'bold',
                            textDecoration: 'var(--textDecor)',
                          }}
                          to="/fr-CA"
                        >
                          National Fran&ccedil;ais
                        </Link>
                      </li>
                      <li>
                        <br />
                        <Menu />
                        <br />
                      </li>
                    </ul>
                    {/* Menu for sub references to other boards */}
                    {post.menuCompile != null && post.menuCompile.boardsSubRef && (
                      <ul>
                        <h5 className="text_upper">Board regions</h5>
                        {post.menuCompile.boardsSubRef.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={`/board/${item.slug}`}
                              activeStyle={{
                                color: '#9E1D30',
                                fontWeight: 'bold',
                                textDecoration: 'var(--textDecor)',
                              }}
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}

                    {/* Menu for board sub pages */}
                    {post.menuCompile != null && (
                      <ul>
                        <h5 className="text_upper">Board Information</h5>
                        {post.menuCompile.boardSubMenu.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={`/board/${item.slug}`}
                              activeStyle={{
                                color: '#9E1D30',
                                fontWeight: 'bold',
                                textDecoration: 'var(--textDecor)',
                              }}
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}

                    {/* menu for MLS sub pages */}
                    <div className="sidebar-wrapper">
                      {post.menuCompile != null &&
                        post.menuCompile.boardMlsMenu && (
                          <ul>
                            <h5 className="text_upper">MLS Statistics</h5>
                            {post.menuCompile.boardMlsMenu.map((item, i) => (
                              <li key={i}>
                                <Link
                                  to={`/mls/${item.slug}`}
                                  activeStyle={{
                                    color: '#9E1D30',
                                    fontWeight: 'bold',
                                    textDecoration: 'var(--textDecor)',
                                  }}
                                >
                                  {item.title}
                                </Link>
                              </li>
                            ))}{' '}
                          </ul>
                        )}
                      {/*       {post.mlsStatistics !== null && (
                        <ul>
                          <h5 className="text_upper">MLS Statistics</h5>

                          {post.mlsStatistics.map((item, i) => (
                            <li key={i}>
                              <Link to={`/mls/${item.slug}`}>
                                {item.MlsboardPageName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )} */}
                    </div>
                    <SideBar />
                  </div>
                </div>
                {/* <!--  ==========================================================  -->
        <!--  END--- DYNAMIC SIDEBAR ==========================  -->
        <!--  ==========================================================  --> */}
              </div>
            </div>
          ) : null}

          <Footer pathname={url} />
        </Wrapper>
        <MobileTab />
      </Container>
    )
  }
}

export default Template
